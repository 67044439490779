import React, { useEffect } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownWideShort, faArrowDownShortWide } from "@fortawesome/free-solid-svg-icons";

import SubClientContent from "./subclients";
import Select from "$components/form-inputs/select";
import Pagination from "$components/table/components/pagination";

import { getTerm } from "$stores/dictionary";
import { scrollToName } from "$utils/scroll-to";
import { SubclientStore, sortSubClients, setPageIndex, setPerPage, setSortBy } from "$stores/subclients";

import "./subclients.scss";

const SubClientList = ({ subClients, totalPercentOfRevenue }) => {
    const { pageCount, pageIndex, perPage, selectedSubClient, sortAsc, sortBy, sortedSubClients } = SubclientStore.useState((s) => {
        return { pageCount: s.pageCount, pageIndex: s.pageIndex, perPage: s.perPage, selectedSubClient: s.selectedSubClient, sortAsc: s.sortAsc, sortBy: s.sortBy, sortedSubClients: s.subClients };
    });

    useEffect(() => {
        if (subClients.length > 0) {
            sortSubClients(subClients);
        }
    }, [subClients]);

    useEffect(() => {
        if (selectedSubClient) {
            scrollToName(`client_${selectedSubClient}`);
        }
    }, [selectedSubClient]);

    const handleSortChange = (name, value) => {
        const defaultValueSort = value?.isAsc;
        setSortBy(value, defaultValueSort);
    };

    const handleSort = () => {
        setSortBy(sortBy, !sortAsc);
    };

    return (
        <div className="subclients-content">
            <div className="flex-header">
                <h3>
                    All <em>{getTerm("subClients")}</em>
                </h3>
                <div className="button-group">
                    <div className="sort-icon" onClick={handleSort}>
                        <FontAwesomeIcon icon={sortAsc ? faArrowDownShortWide : faArrowDownWideShort} />
                    </div>
                    <Select
                        label="Sort By"
                        options={[
                            { text: "Portion of Revenue", value: "portionOfRevenue", isAsc: false },
                            { text: `${getTerm("subClient")} Name`, value: "name", isAsc: true },
                            { text: "Score", value: "healthScore", isAsc: true },
                            { text: getTerm("revenueAtRisk"), value: "revenueAtRisk", isAsc: true },
                        ]}
                        updateOnChange={handleSortChange}
                        defaultValue={sortBy}
                    />
                </div>
            </div>
            {sortedSubClients.slice(pageIndex * perPage, pageIndex * perPage + perPage).map((subClient, i) => (
                <SubClientContent key={`subclient_${subClient.id}_${i}`} id={subClient.id} subClient={subClient} totalPercentOfRevenue={totalPercentOfRevenue} selectedId={selectedSubClient} />
            ))}
            <Pagination
                pageIndex={pageIndex}
                pageCount={pageCount}
                pageSize={perPage}
                setPageIndex={setPageIndex}
                setPerPage={setPerPage}
                canPreviousPage={pageIndex > 0}
                canNextPage={pageIndex < pageCount - 1}
                pageOptions={Array.from({ length: pageCount }, (x, i) => i)}
            />
        </div>
    );
};

export default SubClientList;
