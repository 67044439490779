import React from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

/* CELLS */
import CollapsingInitials from "$components/table/cells/initials/list";
import CollapsingPills from "$components/table/cells/collapsing-pills";
import Flags from "$components/flags";
import LargeNumber from "$components/info-list/content/large-number";
import LogoCell from "$components/table/cells/logo";
import RevenueCell from "./cells/Revenue";
import ScoreBubble from "$components/score-bubble";

import { abbrNum } from "$utils/number-helper";
import { checkIsHealthy } from "$utils/health-helper";
import { getTerm } from "$stores/dictionary";
import { MultiRangeFilter, SelectColumnFilter } from "$components/table/components/filters/filter";

export const buildDefaultColumns = ({ filterOptions, globalFilterRanges }) => {
    return [
        {
            Header: () => getTerm("parentClient"),
            accessorKey: "name", // accessor is the "key" in the data
            id: "name", // id is used as a sort key (i.e., actual api name)
            size: 210,
            className: "-sticky-left",
            Cell: ({ data, value }) => (
                <div>
                    <Flags data={[{ classnames: { "tooltip--center": true, "-independence": data.requiresIndependence }, label: "Check Independence" }]} />
                    <LogoCell value={value} logoUrl={""} />
                </div>
            ), // TODO: logoUrl
        },
        {
            Header: () => getTerm("parentCrms"),
            accessorKey: "crms",
            id: "crms",
            size: 100,
            Cell: ({ data, value }) => <CollapsingInitials data={value} maxVisible={2} keyPrefix="crms" />,
        },
        {
            Header: () => getTerm("industry"),
            accessorKey: "industries",
            size: 120,
            enableSorting: false,
            Cell: ({ data, value }) => {
                const tierOnes = value.filter((v) => v.tier === 1);
                return <CollapsingPills data={tierOnes} maxVisible={2} keyPrefix="industry" analyticsLabel={getTerm("industries")} />;
            },
        },
        {
            Header: () => getTerm("serviceLine"),
            accessorKey: "practices",
            size: 120,
            enableSorting: false,
            Filter: SelectColumnFilter,
            filterOptions: { options: filterOptions?.practice?.values || [] },
            Cell: ({ data, value }) => <CollapsingPills data={value} maxVisible={2} keyPrefix="practice" analyticsLabel={getTerm("serviceLines")} />,
        },
        /*{
            Header: () => "Flags",
            accessorKey: "flags",
            size: 120,
            Filter: SelectColumnFilter,
            filterOptions: {
                options: [
                    { value: "hasGovernmentContractor", text: "Has Government Contractor" },
                    { value: "requiresIndependence", text: "Check Independence" },
                ],
            },
            enableSorting: false,
            Cell: ({ data, value }) => {
                const flags = [];
                if (data.hasGovernmentContractor) {
                    flags.push({ id: "hasGovernmentContractor", text: "Has Government Contractor", modifierClassNames: ["-government-contractor"] });
                }
                if (data.requiresIndependence) {
                    flags.push({ id: "independence", text: "Check Independence", modifierClassNames: ["-independence"] });
                }

                return <CollapsingPills data={flags} maxVisible={2} keyPrefix="flags" analyticsLabel="Flags"  />;
            },
        },*/
        {
            Header: () => "Health Score",
            accessorKey: "healthScore",
            sortDescFirst: true,
            size: 100,
            Filter: MultiRangeFilter,
            filters: {
                min: 1,
                max: 700,
                format: { isCurrency: false },
            },
            Cell: ({ data, value }) => <LargeNumber value={`${value}`} isHealthy={checkIsHealthy(value)} />,
        },
        {
            Header: () => "SVI Score",
            accessorKey: "sviScore",
            sortDescFirst: true,
            size: 75,
            Filter: MultiRangeFilter,
            filters: {
                min: 1,
                max: 10,
                format: { isCurrency: false },
            },
            Cell: ({ data, value }) => {
                return (
                    <div style={{ alignSelf: "center" }}>
                        <ScoreBubble value={value} percent={(value / 10) * 100} postSup={""} graph={true} modifierClassNames={["-small", "-svi"]} />
                    </div>
                );
            },
        },
        {
            Header: () => getTerm("t12Revenue"),
            accessorKey: "revenueHistoryTotal",
            id: "revenueHistoryTotal",
            sortDescFirst: true,
            size: 170,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.twelveMonthRevenueMin ? Math.floor(globalFilterRanges?.twelveMonthRevenueMin) : "",
                max: globalFilterRanges?.twelveMonthRevenueMax ? Math.ceil(globalFilterRanges?.twelveMonthRevenueMax) : "",
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => <RevenueCell value={value} history={data.revenueHistory} />,
        },
        {
            Header: () => getTerm("revenueAtRisk"),
            accessorKey: "revenueAtRisk",
            id: "revenueAtRisk",
            sortDescFirst: true,
            size: 170,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.revenueAtRiskMin ? Math.floor(globalFilterRanges?.revenueAtRiskMin) : "",
                max: globalFilterRanges?.revenueAtRiskMax ? Math.ceil(globalFilterRanges?.revenueAtRiskMax) : "",
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => <LargeNumber value={`$${abbrNum(value)}`} isHealthy={false} />,
        },
        {
            Header: () => <span data-tooltip={getTerm("tooltipGrowthPotential")}>{getTerm("growthPotential")}</span>,
            accessorKey: "growthPotential",
            sortDescFirst: true,
            Filter: MultiRangeFilter,
            filters: {
                min: globalFilterRanges?.growthPotentialMin ? Math.floor(globalFilterRanges?.growthPotentialMin) : "",
                max: globalFilterRanges?.growthPotentialMax ? Math.ceil(globalFilterRanges?.growthPotentialMax) : "",
                format: { isCurrency: true },
            },
            Cell: ({ data, value }) => {
                return <LargeNumber value={`$${abbrNum(value)}`} />;
            },
        },
        {
            Header: () => `Top ${getTerm("potential")}`,
            accessorKey: "topProjectAmount",
            id: "topProjectAmount",
            sortDescFirst: true,
            size: 120,
            enableColumnFilter: false,
            Cell: ({ data, value }) => {
                const topProjectFlags = [];
                if (data.topProjectIsCrossSell) {
                    topProjectFlags.push({ id: "cross-sell", text: "Cross-Sell", modifierClassNames: ["-importance"] });
                }
                return (
                    <div className="top-potential">
                        {value > 0 ? (
                            <div>
                                <LargeNumber value={`$${abbrNum(value)}`} label={data.topProjectType} />
                                {!_.isEmpty(topProjectFlags) && <CollapsingPills data={topProjectFlags} maxVisible={2} keyPrefix="topProjectFlags" analyticsLabel="Top Project Flags" />}
                            </div>
                        ) : (
                            <div className="large-number">
                                <h5>No {getTerm("potentialProjects")}</h5>
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];
};
