import React, { useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";

import AmountWithChange from "$components/amount-with-change";
import LargeNumber from "$components/info-list/content/large-number";
import ScoreBubble from "$components/score-bubble";
import ChartPie from "$components/charts/pie";
import ExternalLegend from "$components/charts/legend/external";
import BarComparison from "$components/charts/bar-comparison";
import TemperatureBar from "$components/charts/temperature-bar";
import TeamHierarchy from "./components/team-hierarchy";

import { calculateScoreMark, checkIsHealthy, getHealthColorClass } from "$utils/health-helper";
import { useDocumentTitle } from "$utils/document-title";
import { abbrNum } from "$utils/number-helper";
import { ChartsStore } from "$stores/charts";
import { ClientDetailsStore } from "$stores/client-details";
import { dealProbabilityText } from "$utils/probability-text";
import { aggregateChildren, limitChartData } from "./lib/chart-helper";
import { getTerm } from "$stores/dictionary";
import { setHelpContent } from "$stores/help";
import { HELP_CLIENT_DASHBOARD } from "../help/lib/help-contents";
import { useNavigate } from "react-router-dom";
import { setFilters as setExistingFilters } from "$stores/clientExistingProjects";
import { setFilters as setPotentialFilters } from "$stores/potentialProjects";
import HealthHistory from "./components/health-history";
import { emifyIt } from "$utils/text-helpers";

const ClientDashboard = () => {
    useDocumentTitle("Client Dashboard");
    const navigate = useNavigate();
    const { hasSubClients, clientDetails } = ClientDetailsStore.useState((s) => {
        return { hasSubClients: s.hasSubClients, clientDetails: s.clientDetails };
    });

    const { t12TotalRevenueByClient, t12TotalRevenueByIndustry, t12TotalRevenueByServiceLine, t12TotalRevenueByProjectType, growthByProjectType, growthByIndustry, growthByServiceLine } = ChartsStore.useState((s) => {
        return {
            t12TotalRevenueByClient: limitChartData(s.charts["t12TotalRevenueByClient"], 4),
            t12TotalRevenueByIndustry: limitChartData(s.charts["t12TotalRevenueByIndustry"], 4),
            t12TotalRevenueByServiceLine: limitChartData(s.charts["t12TotalRevenueByServiceLine"], 4),
            t12TotalRevenueByProjectType: limitChartData(s.charts["t12TotalRevenueByProjectType"], 4),
            growthByProjectType: limitChartData(s.charts["growthByProjectType"], 9),
            growthByServiceLine: limitChartData(s.charts["growthByServiceLine"], 9),
            growthByIndustry: limitChartData(s.charts["growthByIndustry"], 9),
        };
    });

    useEffect(() => {
        setHelpContent(HELP_CLIENT_DASHBOARD);
    }, []);

    const setExistingProjectsFilter = (key, value) => {
        setExistingFilters({ [key]: value });
        navigate(`/client/${clientDetails?.id}/existing-projects#existing-projects`);
    };
    const setPotentialProjectsFilter = (key, value) => {
        // TODO: Service Line
        setPotentialFilters({ [key]: value });
        navigate(`/client/${clientDetails?.id}/growth-potential#potential-projects`);
    };

    return (
        <div className="dashboard-cards">
            <div className="card -crm">
                <TeamHierarchy
                    crms={clientDetails.crms || []}
                    clientManagers={clientDetails.clientManagers || []}
                    cmTitle={`${getTerm("subCustomerRelationsManagers")}${getTerm("clientManagers") ? ` & ${getTerm("clientManagers")}` : ""}`}
                />
            </div>

            <div className="card -office">
                <div className="icon">
                    <FontAwesomeIcon icon={faLandmark} />
                </div>
                <h5>Primary Office</h5>

                <h3>{clientDetails?.primaryOfficeName}</h3>
            </div>

            <div className="card -office">
                <div className="txt-left">
                    <h5>{getTerm("serviceLine")}</h5>
                    <h3>{(clientDetails?.practices || []).map((p) => p.text).join(", ")}</h3>
                    <h5>{getTerm("industry")}</h5>
                    <h3>{(clientDetails?.industries || []).map((i) => i.text).join(", ")}</h3>
                    {clientDetails?.mergers && clientDetails.mergers.length > 0 && (
                        <>
                            <h5>Merger</h5>
                            <h3>{(clientDetails?.mergers).map((i) => i?.text).join(", ")}</h3>
                        </>
                    )}
                </div>
            </div>

            <div className="card -office" style={{ textAlign: "left" }}>
                <TemperatureBar percent={clientDetails?.sviScore * 10} hasDot={true} isHealthy={false} cssClass={["-importance"]}>
                    <span>Strategic Importance: </span>
                    <span className="txt -importance -bold">{dealProbabilityText(clientDetails?.sviScore * 10)}</span>
                </TemperatureBar>
                <br />
                <br />
                <TemperatureBar percent={(clientDetails?.healthScore / 700) * 100} hasDot={true} isHealthy={checkIsHealthy(clientDetails?.healthScore)}>
                    <span>Health Score: </span>
                    <span className={classNames("txt -bold", getHealthColorClass(clientDetails?.healthScore))}>{calculateScoreMark(clientDetails?.healthScore).label}</span>
                </TemperatureBar>
            </div>

            <div className="card -header">
                <h2>
                    <span data-tooltip={getTerm("tooltip12MonthRevenue")} className="tooltip--up">
                        {emifyIt(getTerm("t12Revenue"))}
                    </span>
                </h2>
                <AmountWithChange
                    isHealthy={true}
                    value={`$${abbrNum(clientDetails?.twelveMonthRevenue)}`}
                    changeValue={`$${abbrNum(clientDetails?.twelveMonthRevenueChange)}`}
                    changePercent={abbrNum(clientDetails?.twelveMonthRevenueChangePercent, { showSuffix: false })}
                />
            </div>

            <div className="card">
                <h5 className="txt -center">
                    <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> By {getTerm("subClient")}
                </h5>

                <div className="chart-legend">
                    <BarComparison
                        data={t12TotalRevenueByClient}
                        onClick={(data) => {
                            setExistingProjectsFilter("clientName", data.text);
                        }}
                        preSup="$"
                    />
                </div>
            </div>

            <div className="card">
                <h5 className="txt -center -white">
                    <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> By {getTerm("serviceLine")}
                </h5>

                <div className="chart-legend">
                    <BarComparison
                        data={t12TotalRevenueByServiceLine}
                        onClick={(data) => {
                            setExistingProjectsFilter("serviceLine", [{ text: data?.text, value: data.value }]);
                        }}
                        preSup="$"
                    />
                </div>
            </div>
            <div className="card">
                <h5 className="txt -center -white">
                    <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> By {getTerm("industry")}
                </h5>

                <div className="chart-legend">
                    <BarComparison
                        data={t12TotalRevenueByIndustry}
                        onClick={(data) => {
                            setExistingProjectsFilter("industry", data.text);
                        }}
                        preSup="$"
                    />
                </div>
            </div>
            <div className="card">
                <h5 className="txt -center -white">
                    <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> By {getTerm("projectTypes")}
                </h5>

                <div className="chart-legend">
                    <BarComparison
                        data={t12TotalRevenueByProjectType}
                        onClick={(data) => {
                            setExistingProjectsFilter("projectType", data.text);
                        }}
                        preSup="$"
                    />
                </div>
            </div>

            <div className="card -header">
                <h2>
                    {emifyIt(getTerm("growthPotential"))} {/* TODO: EM-ify */}
                </h2>
                <AmountWithChange
                    value={`$${abbrNum(clientDetails?.growthPotential)}`}
                    changeValue={`$${abbrNum(clientDetails?.growthPotentialChange)}`}
                    changePercent={abbrNum(clientDetails?.growthPotentialChangePercent, { showSuffix: false })}
                />
            </div>
            {hasSubClients && (
                <>
                    {growthByIndustry && (
                        <div className="card -span2">
                            <h4 className="txt -center -white">
                                {getTerm("growthPotential")} By {getTerm("industry")} & {getTerm("subClient")}
                            </h4>
                            <div className="chart-legend">
                                <ChartPie
                                    charts={[
                                        {
                                            data: growthByIndustry,
                                            outerRadius: 105,
                                            innerRadius: 50,
                                            onClick: (data) => {
                                                setPotentialProjectsFilter("industry", data?.label);
                                            },
                                        },
                                        {
                                            data: aggregateChildren(growthByIndustry),
                                            outerRadius: 170,
                                            innerRadius: 115,
                                            onClick: (data) => {
                                                setPotentialProjectsFilter("clientName", data?.label);
                                            },
                                        },
                                    ]}
                                />
                                <ExternalLegend
                                    data={growthByIndustry}
                                    onClick={(data) => {
                                        setPotentialProjectsFilter("industry", data?.label);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {growthByServiceLine && (
                        <div className="card -span2">
                            <h4 className="txt -center -white">
                                {getTerm("growthPotential")} By {getTerm("serviceLine")} & {getTerm("projectType")}
                            </h4>
                            <div className="chart-legend">
                                <ChartPie
                                    charts={[
                                        {
                                            data: growthByServiceLine,
                                            outerRadius: 105,
                                            innerRadius: 50,
                                            onClick: (data) => {
                                                setPotentialProjectsFilter("serviceLine", [{ text: data?.label, value: data?.labelId }]);
                                            },
                                        },
                                        {
                                            data: aggregateChildren(growthByServiceLine),
                                            outerRadius: 170,
                                            innerRadius: 115,
                                            onClick: (data) => {
                                                setPotentialProjectsFilter("name", data?.label);
                                            },
                                        },
                                    ]}
                                />
                                <ExternalLegend
                                    data={growthByServiceLine}
                                    onClick={(data) => {
                                        setPotentialProjectsFilter("serviceLine", [{ text: data?.label, value: data?.labelId }]);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            {!hasSubClients && (
                <>
                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            {getTerm("growthPotential")} By {getTerm("serviceLine")}
                        </h4>
                        <div className="chart-legend">
                            <ChartPie
                                charts={[
                                    {
                                        data: growthByServiceLine,
                                        outerRadius: 105,
                                        innerRadius: 50,
                                        onClick: (data) => {
                                            setPotentialProjectsFilter("serviceLine", [{ text: data?.label, value: data?.labelId }]);
                                        },
                                    },
                                ]}
                            />
                            <ExternalLegend
                                data={growthByServiceLine}
                                onClick={(data) => {
                                    setPotentialProjectsFilter("serviceLine", [{ text: data?.label, value: data?.labelId }]);
                                }}
                            />
                        </div>
                    </div>

                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            {getTerm("growthPotential")} By {getTerm("projectType")}
                        </h4>
                        <div className="chart-legend">
                            <ChartPie
                                charts={[
                                    {
                                        data: growthByProjectType,
                                        outerRadius: 105,
                                        innerRadius: 50,
                                        onClick: (data) => {
                                            setPotentialProjectsFilter("name", data?.label);
                                        },
                                    },
                                ]}
                            />
                            <ExternalLegend
                                data={growthByProjectType}
                                onClick={(data) => {
                                    setPotentialProjectsFilter("name", data?.label);
                                }}
                            />
                        </div>
                    </div>
                </>
            )}

            <div className="card -header">
                <h2>
                    <em>Health Score</em> Over Time
                </h2>
                <ScoreBubble
                    label="Health Score"
                    graph={true}
                    value={clientDetails?.healthScore}
                    healthy={checkIsHealthy(clientDetails?.healthScore)}
                    percent={(clientDetails?.healthScore / 700) * 100}
                    modifierClassNames={["-small -center"]}
                />
            </div>
            <div className="card -span4">
                <HealthHistory />
            </div>
        </div>
    );
};

export default ClientDashboard;
