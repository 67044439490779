import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faGear } from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import debounce from "debounce";
import classNames from "classnames";

import NotificationsSettings from "./components/setting";
import NotificationMessageList from "./components/message-list";

import { PanelContext } from "$contexts/panels";
import { unwatchNotifications, watchNotifications, NotificationStore } from "$stores/notifications";

import "./notification-panel.scss";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { capitalizeFirstLetter } from "$utils/text-helpers";

const debouncedStartwebsocket = debounce(() => {
    watchNotifications();
}, 1000);

const NotificationPanel = ({ isActive }) => {
    const appInsights = useAppInsightsContext();
    const [inboxState, setInboxState] = useState("active");
    const { active, archived } = NotificationStore.useState((n) => {
        return { active: n.active || [], archived: n.archived || [] };
    });
    const { clearTimer, closePanel } = useContext(PanelContext);

    useEffect(() => {}, [isActive]);

    useEffect(() => {
        debouncedStartwebsocket();
        return () => {
            unwatchNotifications();
        };
    }, []);

    const toggleInbox = (type) => {
        setInboxState(type);

        appInsights.trackEvent(
            { name: `Notifications - ${capitalizeFirstLetter(type)}` },
            {
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    return (
        <div
            className={classNames("notification-panel", { "-active": isActive })}
            onMouseLeave={() => {
                closePanel("notifications");
            }}
            onMouseEnter={() => {
                clearTimer();
            }}
        >
            <header className="flex-header">
                <h2 className="txt -uppercase">
                    Notifications <em className="memo">{inboxState.toUpperCase()}</em>
                </h2>
                <div className="button-list">
                    <button className={classNames("button -dark -small", { "-active": inboxState === "active" })} onClick={() => toggleInbox("active")} alt="Active Notifications">
                        <FontAwesomeIcon icon={faFolderOpen} />
                    </button>
                    <button className={classNames("button -dark -small", { "-active": inboxState === "archived" })} onClick={() => toggleInbox("archived")} alt="Archived Notifications">
                        <FontAwesomeIcon icon={faBoxArchive} />
                    </button>
                    {/*<button className={classNames("button -dark -small", { "-active": inboxState === "settings" })} onClick={() => toggleInbox("settings")} alt="Notifications Settings">
                        <FontAwesomeIcon icon={faGear} />
        </button>*/}
                </div>
            </header>
            {inboxState === "active" && <NotificationMessageList messages={active} />}
            {inboxState === "archived" && <NotificationMessageList messages={archived} />}
            {inboxState === "settings" && <NotificationsSettings />}
        </div>
    );
};

export default NotificationPanel;
