import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import { ModalStore, closeModal } from "$stores/modal";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useRef } from "react";
import { KEYCODE } from "$utils/keycodes";

import "./modal.scss";

/*var throttled = false;
var delay = 500;

const throttleSetModalCenter = () => {
    if (!throttled) {
        // we're throttled!
        throttled = true;
        // set a timeout to un-throttle
        setTimeout(function () {
            // actual callback action
            setModalCenter();
            throttled = false;
        }, delay);
    }
};

const setModalCenter = () => {
    const el = document.getElementById("modal");
    if (!el) {
        return;
    }

    const modalHeight = el.offsetHeight;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if (modalHeight < windowHeight) {
        el.classList.add("-center");
    } else {
        el.classList.remove("-center");
    }
};*/

const typeIcon = {
    confirm: faExclamation,
};

const Modal = () => {
    const appInsights = useAppInsightsContext();
    const modalRef = useRef();
    const { Component, data, open, disableClose, analyticsLabel, type } = ModalStore.useState((m) => {
        return { Component: m.Component, data: m.data, open: m.isOpen, disableClose: m.disableClose, analyticsLabel: m.analyticsLabel, type: m.type };
    });

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (open) {
            modalRef.current.showModal();
            appInsights.trackEvent(
                { name: analyticsLabel ? `Modal - ${analyticsLabel}` : "Modal" },
                {
                    page: document.title.replace(" - Orgaimi", ""),
                    action: "open",
                }
            );
        } else {
            modalRef.current.close();
        }
    }, [open]);

    const handleKeyDown = (event) => {
        switch (event.keyCode) {
            case KEYCODE.ESC:
                event.preventDefault();
                //closeModal();
                break;
            default:
                break;
        }
    };

    return (
        <dialog ref={modalRef} id="modal" className={classNames("modal", { [type]: type })}>
            {type === "confirm" && (
                <div className="display-icon">
                    <FontAwesomeIcon icon={typeIcon[type] || faExclamation} />
                </div>
            )}
            {!disableClose && (
                <div
                    className="modal-close"
                    onClick={() => {
                        closeModal();
                        appInsights.trackEvent(
                            { name: analyticsLabel ? `Modal - ${analyticsLabel}` : "Modal" },
                            {
                                page: document.title.replace(" - Orgaimi", ""),
                                action: "close",
                            }
                        );
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            )}
            {Component && <Component {...data} isOpen={open} />}
        </dialog>
    );
};

export default Modal;
