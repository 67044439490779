import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import "./pills.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

const CollapsingPills = ({ data, maxVisible, keyPrefix, analyticsLabel = "Collapsing Pills" }) => {
    const appInsights = useAppInsightsContext();
    const [showExtra, setShowExtra] = useState(false);
    const [hover, setHover] = useState(false);

    const [visibleData, setVisibleData] = useState([]);
    const [hiddenData, setHiddenData] = useState([]);

    const onHover = () => {
        if (!showExtra) {
            setHover(true);

            appInsights.trackEvent(
                { name: "Show More" },
                {
                    label: analyticsLabel,
                    action: "hover",
                }
            );
        }
    };

    const onLeave = () => {
        setHover(false);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const max = maxVisible <= data.length ? maxVisible : data.length;
            const visibleData = data.slice(0, max);
            const hiddenData = data.slice(max) || [];
            setVisibleData(visibleData);
            setHiddenData(hiddenData);
        }
    }, [data, maxVisible]);

    return (
        <div className="collapsing-pills">
            <ul className="pill-list">
                {visibleData.length > 0 &&
                    visibleData.map((v, i) => {
                        return (
                            <li key={`pill-${keyPrefix}-${v.id}-${i}`} className={classNames(v.modifierClassNames || [])}>
                                <span>
                                    {v.icon && (
                                        <em className="icon">
                                            <FontAwesomeIcon icon={v.icon} />
                                        </em>
                                    )}
                                    {v.text}
                                </span>
                            </li>
                        );
                    })}
            </ul>
            <div className="extra-container">
                <div className={classnames("extra", { "-show": showExtra })}>
                    <ul className="pill-list">
                        {hiddenData.length > 0 &&
                            hiddenData.map((v, i) => {
                                return (
                                    <li key={`pill-${keyPrefix}-${v.id}-${i}`}>
                                        <span>{v.text}</span>
                                    </li>
                                );
                            })}
                    </ul>
                </div>

                {hiddenData.length > 0 && (
                    <button
                        className={classnames("show-more", { "-show": showExtra })}
                        onMouseEnter={onHover}
                        onMouseLeave={onLeave}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowExtra(!showExtra);
                            setHover(false);
                            appInsights.trackEvent(
                                { name: "Show More" },
                                {
                                    label: "Collapsing Pills",
                                    action: !showExtra ? "close" : "open",
                                }
                            );
                        }}
                    >
                        {showExtra ? "^" : `+ ${hiddenData.length}`}
                    </button>
                )}
                <div className={classnames("tooltip", { "-hover": hover })}>
                    <ul className="pill-list">
                        {hiddenData.length > 0 &&
                            hiddenData.map((v, i) => {
                                return (
                                    <li key={`pill-${keyPrefix}-${v.id}-${i}`}>
                                        <span>{v.text}</span>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CollapsingPills;
