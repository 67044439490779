import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLandmark, faMessage } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import BarComparison from "$components/charts/bar-comparison";
import ChartPie from "$components/charts/pie";
import CollapsingPills from "$components/table/cells/collapsing-pills";
import ExternalLegend from "$components/charts/legend/external";
import PercentLegend from "$components/charts/legend/components/percent-legend";

import { buildTeamTop5Sections } from "./lib/constants";
import { ChartsStore } from "$stores/charts";
import { EmployeeDetailsStore } from "$stores/employee-details";
import { useDocumentTitle } from "$utils/document-title";
import { getTerm } from "$stores/dictionary";
import { aggregateChildren, limitChartData } from "../clients/lib/chart-helper";
import { buildChatUrl } from "$utils/chat-type";
import { LabelPercentTooltip } from "$components/charts/tooltips/currency";
import { AppContextStore } from "$stores/app-context";
import classNames from "classnames";
import { setHelpContent } from "$stores/help";
import { HELP_TEAM_DASHBOARD } from "../help/lib/help-contents";
import { setFilters } from "$stores/employeeExistingProjects.js";

const TeamDashboard = () => {
    useDocumentTitle("Employee Dashboard");

    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const [top5Titles] = useState(buildTeamTop5Sections());
    const { employeeDetails, top5 } = EmployeeDetailsStore.useState((s) => {
        return { employeeDetails: s.employeeDetails, top5: s.top5 };
    });
    const { enableTeams } = AppContextStore.useState((a) => {
        return { enableTeams: a.settings?.externalConnections?.teams && employeeDetails.isActive };
    });

    const { employeeHoursByClient, employeeHoursByClientGroup, employeeHoursByIndustry, employeeHoursByProjectType, employeeHoursByServiceLine } = ChartsStore.useState((s) => {
        return {
            employeeHoursByClient: limitChartData(s.charts["employeeHoursByClient"], 4),
            employeeHoursByClientGroup: limitChartData(s.charts["employeeHoursByClientGroup"], 4),
            employeeHoursByIndustry: limitChartData(s.charts["employeeHoursByIndustry"], 4),
            employeeHoursByProjectType: limitChartData(s.charts["employeeHoursByProjectType"], 4),
            employeeHoursByServiceLine: limitChartData(s.charts["employeeHoursByServiceLine"], 9),
        };
    });

    useEffect(() => {
        setHelpContent(HELP_TEAM_DASHBOARD);
    }, []);

    const setExistingProjectFilter = (id, value) => {
        setFilters({
            [id]: value,
        });
        navigate(`/team/${employeeDetails.id}/past-work#existing-projects`);
    };

    return (
        <div>
            <div className="dashboard-cards">
                {employeeDetails.isActive && (
                    <div className="card -office">
                        <a
                            href={buildChatUrl("email", employeeDetails?.email)}
                            target="_blank"
                            onClick={() => {
                                appInsights.trackEvent(
                                    { name: "Team Contact" },
                                    {
                                        label: employeeDetails?.id,
                                        page: document.title.replace(" - Orgaimi", ""),
                                        action: "Email",
                                    }
                                );
                            }}
                        >
                            <div className="icon">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <h5>Email</h5>

                            <h3>{employeeDetails?.email}</h3>
                        </a>
                    </div>
                )}
                {enableTeams && (
                    <div className="card -office">
                        <a
                            href={buildChatUrl("teams", employeeDetails?.email)}
                            target="_blank"
                            onClick={() => {
                                appInsights.trackEvent(
                                    { name: "Team Contact" },
                                    {
                                        label: employeeDetails?.id,
                                        page: document.title.replace(" - Orgaimi", ""),
                                        action: "Microsoft Teams",
                                    }
                                );
                            }}
                        >
                            <div className="icon">
                                <FontAwesomeIcon icon={faMessage} />
                            </div>
                            <h5>Message</h5>

                            <h3>Teams Chat</h3>
                        </a>
                    </div>
                )}
                <div className={classNames("card -office", { "-span2": !employeeDetails.isActive })}>
                    <div className="icon">
                        <FontAwesomeIcon icon={faLandmark} />
                    </div>
                    <h5>Primary Office</h5>

                    <h3>{employeeDetails?.homeOffice}</h3>
                </div>
                <div className={classNames("card -office", { "-span2": !enableTeams })}>
                    <h5>Roles</h5>
                    <CollapsingPills data={employeeDetails?.employeeMetadata} maxVisible={10} keyPrefix="roles" analyticsLabel="Roles" />
                </div>
            </div>
            <br />
            <br />
            <div className="dashboard-cards">
                <div className="card -span2">
                    <h4 className="txt -center -white">
                        <em>Time Spent</em> By {getTerm("parentClient")} & {getTerm("subClient")}
                    </h4>
                    <div className="chart-legend">
                        <ChartPie
                            charts={[
                                {
                                    data: employeeHoursByClientGroup,
                                    outerRadius: 105,
                                    innerRadius: 50,
                                    onClick: (data) => {
                                        setExistingProjectFilter("clientGroupName", data.label);
                                    },
                                },
                                {
                                    data: aggregateChildren(employeeHoursByClientGroup),
                                    outerRadius: 170,
                                    innerRadius: 115,
                                    onClick: (data) => {
                                        setExistingProjectFilter("clientName", data.label);
                                    },
                                },
                            ]}
                            TooltipComponent={LabelPercentTooltip}
                        />
                        <ExternalLegend
                            data={employeeHoursByClientGroup}
                            Component={PercentLegend}
                            onClick={(data) => {
                                setExistingProjectFilter("clientGroupName", data.label);
                            }}
                        />
                    </div>
                </div>
                <div className="card -span2 -clear">
                    <div className="dashboard-cards">
                        {Object.keys(top5Titles).map((key) => {
                            /*return (
                                <div key={`card_${key}`} className="card -span2">
                                    {top5Titles[key]?.header()}
                                    <div className="chart-legend">
                                        <ChartPie
                                            charts={[
                                                {
                                                    data: eval(top5Titles[key].chartKey),
                                                    outerRadius: 105,
                                                    innerRadius: 50,
                                                },
                                            ]}
                                            TooltipComponent={top5Titles[key].ChartTooltipComponent}
                                        />
                                        <ExternalLegend data={eval(top5Titles[key].chartKey)} Component={top5Titles[key].LegendComponent} />
                                    </div>
                                </div>
                            );*/
                            return (
                                <div key={`card_${key}`} className="card -span2">
                                    {top5Titles[key]?.header()}
                                    <div className="chart-legend">
                                        <BarComparison
                                            data={eval(top5Titles[key].chartKey)}
                                            preSup=""
                                            postSup="%"
                                            modifyFn={(value) => value.toFixed(1)}
                                            onClick={(data) => {
                                                const useValue = key === "topServiceLines" ? [data] : data.text;
                                                setExistingProjectFilter(top5Titles[key].accessorKey, useValue);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamDashboard;
