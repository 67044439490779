import React, { useState } from "react";
import classNames from "classnames";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const AccordionItem = ({ title, Content }) => {
    const appInsights = useAppInsightsContext();
    const [showHidden, setShowHidden] = useState(false);

    const toggleHidden = () => {
        setShowHidden(!showHidden);

        appInsights.trackEvent(
            { name: "Show More - Help" },
            {
                label: title,
                action: !showHidden ? "close" : "open",
            }
        );
    };

    return (
        <div>
            <div className="title">
                <h3>
                    <span>{title}</span>
                </h3>
                <button className={classNames("button -icon -text  btn-accordion", { "-active": showHidden })} onClick={toggleHidden}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </button>
            </div>
            <div className={classNames("hidden-content", { "-active": showHidden })}>
                <Content />
            </div>
        </div>
    );
};

const HelpAccordion = ({ data = [] }) => {
    return (
        <div className="help-accordion">
            {data.map((a, i) => {
                return <AccordionItem key={`help-accordion_${i}`} title={a.title} Content={a.Content} />;
            })}
        </div>
    );
};

export default HelpAccordion;
