import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faAnglesRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useInput } from "$utils/hooks/use-input";
import { Input } from "$components/form-inputs/inputs";
import Select from "$components/form-inputs/select";

var pageTimeout = null;

const constrainMinMax = (value, min, max) => {
    return value < min ? min : value > max ? max : value;
};

const perPageOptions = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
];

const Pagination = ({ pageIndex, pageCount, pageSize, setPageIndex, setPerPage }) => {
    const pageInput = useInput(pageIndex + 1);
    const perPageSelect = useInput(pageSize);

    useEffect(() => {
        if (pageInput.value - 1 !== pageIndex) {
            pageInput.setValue(Number(pageIndex) + 1);
        }
    }, [pageIndex]);

    useEffect(() => {
        clearTimeout(pageTimeout);
        pageTimeout = setTimeout(() => {
            if (isNaN(pageInput.value)) {
                pageInput.setValue(1);
                return;
            }

            const validPage = constrainMinMax(Number(pageInput.value), 1, pageCount);

            if (pageInput.value === "") {
                return;
            }

            if (validPage - 1 !== pageIndex) {
                setPageIndex(validPage - 1);
            }
            if (validPage !== pageInput.value) {
                pageInput.setValue(validPage);
            }
        }, 500);
    }, [pageInput.value]);

    useEffect(() => {
        const perPage = perPageSelect.value?.value ? perPageSelect.value?.value : perPageSelect.value || 10;
        if (setPerPage && perPage !== pageSize) {
            setPerPage(perPage);
        }
    }, [perPageSelect.value]);

    return (
        <div className="pagination">
            {Number(pageInput.value) > 1 && (
                <div
                    className="first"
                    onClick={() => {
                        pageInput.setValue(1);
                    }}
                    disabled={Number(pageInput.value) <= pageCount}
                >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </div>
            )}
            {Number(pageInput.value) > 1 && (
                <div
                    className="previous"
                    onClick={() => {
                        pageInput.setValue(Number(pageInput.value) - 1);
                    }}
                    disabled={pageInput.value <= pageCount}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </div>
            )}
            <div className="page-of">
                <Input id="page-of" {...pageInput.bind} max={pageCount} min={1} />
                <div className="of">
                    <strong> of {pageCount || 1}</strong>{" "}
                </div>
            </div>
            {Number(pageInput.value) < pageCount && (
                <div
                    className="next"
                    onClick={() => {
                        pageInput.setValue(Number(pageInput.value ? Number(pageInput.value) : 1) + 1);
                    }}
                    disabled={pageIndex < pageCount}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </div>
            )}
            {Number(pageInput.value) < pageCount && (
                <div
                    className="last"
                    onClick={() => {
                        pageInput.setValue(pageCount);
                    }}
                    disabled={Number(pageInput.value) === pageCount}
                >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                </div>
            )}

            <Select {...perPageSelect.bind} defaultValue={perPageSelect.value} label="Per Page" options={perPageOptions} />
        </div>
    );
};

export default Pagination;
