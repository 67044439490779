import React, { useEffect, useState } from "react";
import _ from "lodash";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEnvelope, faLandmark, faMessage } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useLocation, useNavigate } from "react-router-dom";

import BarComparison from "$components/charts/bar-comparison";
import Button from "$components/button";
import ChartPie from "$components/charts/pie";
import ExternalLegend from "$components/charts/legend/external";
import ProbabilityBubble from "$components/probability";
import RadarPolarChart from "$components/charts/radar-chart";
import SemiCirclePie from "$components/charts/semi-circle-pie";
import StatWithChange from "$components/dashboard/components/stat";
import ScoreBubble from "$components/score-bubble";
import TeamHierarchy from "../team-hierarchy";

import { checkIsHealthy } from "$utils/health-helper";
import { ChartsStore, fetchCharts } from "$stores/charts";
import { aggregateChildren, limitChartData } from "../../lib/chart-helper";
import { roundAboveOne } from "$utils/number-helper";
import { COLOR_FONT, COLOR_GRAY, COLOR_POTENTIAL } from "$utils/colors";
import { LabelPercentTooltip } from "$components/charts/tooltips/label-percent";
import { getTerm } from "$stores/dictionary";
import { buildChatUrl } from "$utils/chat-type";
import { AppContextStore } from "$stores/app-context";
import { setFilters as setExistingFilters } from "$stores/clientExistingProjects";
import { setFilters as setPotentialProjects } from "$stores/potentialProjects";
import classNames from "classnames";

const SubClientContent = ({ id, selectedId, subClient, totalPercentOfRevenue }) => {
    const location = useLocation();
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    const [showHidden, setShowHidden] = useState(false);
    const [charts, setCharts] = useState({});
    const { clientGroupThemeScoreByThemeId } = ChartsStore.useState((c) => {
        return {
            clientGroupThemeScoreByThemeId: (c.charts["clientGroupThemeScore"] || []).reduce((obj, t, i) => {
                obj[t.labelId] = t.value;
                return obj;
            }, {}),
        };
    });
    const { enableTeams } = AppContextStore.useState((a) => {
        return { enableTeams: a.settings?.externalConnections?.teams };
    });

    useEffect(() => {
        const hash = location.hash;
        if (hash && hash.replace("#client_", "") === subClient.id.toString() && !showHidden) {
            toggleHidden();
        }
    }, [location]);

    useEffect(() => {
        if (selectedId === id && !showHidden) {
            toggleHidden();
        }
    }, [selectedId]);

    const toggleHidden = async () => {
        setShowHidden(!showHidden);
        if (!showHidden && _.isEmpty(charts)) {
            const newCharts = await fetchCharts({ client: subClient.id }, [
                "t12TotalRevenueByServiceLine",
                "t12TotalRevenueByProjectType",
                { key: "growthByServiceLine", groupChildrenFrom: "growthByProjectType" },
                "growthByProjectType",
                { key: "clientThemeScore", sortBy: "labelId" },
            ]);
            setCharts({
                t12TotalRevenueByServiceLine: limitChartData(newCharts["t12TotalRevenueByServiceLine"]),
                t12TotalRevenueByProjectType: limitChartData(newCharts["t12TotalRevenueByProjectType"]),
                growthByServiceLine: limitChartData(newCharts["growthByServiceLine"]),
                growthByProjectType: limitChartData(newCharts["growthByProjectType"]),
                clientThemeScore: newCharts["clientThemeScore"].map((t, i) => {
                    return {
                        label: t.label,
                        clientGroup: clientGroupThemeScoreByThemeId[t.labelId] || 0,
                        client: t.value,
                    };
                }),
            });
        }

        appInsights.trackEvent(
            { name: `Show More - ${getTerm("subClients")}` },
            {
                label: subClient?.name,
                action: !showHidden ? "close" : "open",
            }
        );
    };

    const setExistingProjectsFilter = (key, value) => {
        setExistingFilters({
            [key]: value,
            clientName: subClient.name,
        });
        navigate(`/client/${subClient?.clientGroupid}/existing-projects`);
    };

    const setPotentialProjectsFilter = (key, value) => {
        setPotentialProjects({
            [key]: value,
            clientName: subClient.name,
        });
        navigate(`/client/${subClient?.clientGroupid}/growth-potential#potential-projects`);
    };

    return (
        <div name={`client_${subClient.id}`} className="subclients-panel">
            <img className="theme-image" src="/images/theme-cross-collaboration.svg" alt={id} />
            <div className="title" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="subclient-dashboard">
                    <div className="client-logo">
                        <h2>{subClient.name}</h2>
                    </div>
                    {!isNaN(subClient?.twelveMonthRevenue) && (
                        <StatWithChange label={getTerm("t12Revenue")} tooltip={getTerm("tooltip12MonthRevenue")} value={subClient.twelveMonthRevenue} increase={undefined} isHealthy={true} />
                    )}
                    <div className="divide"></div>
                    {!isNaN(subClient?.growthPotential) && <StatWithChange label={getTerm("growthPotential")} value={subClient.growthPotential} increase={undefined} />}
                    <div className="divide"></div>
                    {!isNaN(subClient?.revenueAtRisk) && <StatWithChange label={getTerm("revenueAtRisk")} value={subClient.revenueAtRisk} increase={undefined} />}
                    <div className="divide"></div>
                    <div className="stat-group">
                        <div>
                            <ScoreBubble graph={true} value={subClient?.healthScore} healthy={checkIsHealthy(subClient?.healthScore)} percent={(subClient?.healthScore / 700) * 100} modifierClassNames={["-small"]} />
                            <h4 className="txt -center">Health</h4>
                        </div>
                        &nbsp; &nbsp;
                        <div>
                            <ProbabilityBubble value={subClient?.sviScore * 10} cssClasses={["-importance"]} />
                            <h4 className="txt -center">SVI</h4>
                        </div>
                    </div>
                    <div className="divide"></div>
                    <div className="percent-revenue">
                        <div>
                            <SemiCirclePie
                                outerRadius={56}
                                innerRadius={45}
                                TooltipComponent={LabelPercentTooltip}
                                data={[
                                    { id: 1, text: subClient.name, value: subClient.portionOfRevenue },
                                    { id: 0, text: "Other", value: totalPercentOfRevenue - subClient.portionOfRevenue },
                                    { id: 2, text: "Churned", value: 100 - totalPercentOfRevenue },
                                ]}
                                colors={["#de653a", "#1c3642", COLOR_GRAY]}
                                totalValue={`${roundAboveOne(subClient.portionOfRevenue)}%`}
                            />
                        </div>
                        <h4 className="txt -center">Portion of Revenue</h4>
                    </div>
                </div>
            </div>
            <br />
            <Button className={classnames("button -text", { "-active": showHidden })} onClick={toggleHidden}>
                <FontAwesomeIcon icon={faChevronDown} />
            </Button>
            <div className={classnames("hidden-content", { "-active": showHidden })}>
                <div className="dashboard-cards">
                    {subClient.crms.length > 0 && (
                        <>
                            <div className="card -crm">
                                <TeamHierarchy crms={subClient.crms || []} crmTitle={getTerm("subCustomerRelationsManager")} clientManagers={subClient.clientManagers || []} cmTitle={getTerm("clientManagers")} />
                            </div>
                            {subClient.crms[0].isActive && (
                                <div className="card -office">
                                    {!enableTeams && (
                                        <a
                                            href={buildChatUrl("email", subClient.crms[0].email)}
                                            target="_blank"
                                            onClick={() => {
                                                appInsights.trackEvent(
                                                    { name: "Email - Employee" },
                                                    {
                                                        label: subClient.crms[0].id,
                                                        page: document.title.replace(" - Orgaimi", ""),
                                                        action: "Email",
                                                    }
                                                );
                                            }}
                                        >
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </div>
                                            <h5>Email</h5>

                                            <h3>{subClient.crms[0]?.email}</h3>
                                        </a>
                                    )}
                                    {enableTeams && (
                                        <>
                                            <a
                                                href={buildChatUrl("email", subClient.crms[0].email)}
                                                target="_blank"
                                                onClick={() => {
                                                    appInsights.trackEvent(
                                                        { name: "Team Contact" },
                                                        {
                                                            label: subClient.crms[0].id,
                                                            page: document.title.replace(" - Orgaimi", ""),
                                                            action: "Email",
                                                        }
                                                    );
                                                }}
                                            >
                                                <div className="small-icon">
                                                    <div className="icon">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </div>
                                                    <h5>Email</h5>
                                                </div>

                                                <h3>{subClient.crms[0]?.email}</h3>
                                            </a>

                                            <a
                                                href={buildChatUrl("teams", subClient.crms[0]?.email)}
                                                target="_blank"
                                                onClick={() => {
                                                    appInsights.trackEvent(
                                                        { name: "Team Contact" },
                                                        {
                                                            label: subClient.crms[0]?.id,
                                                            action: "Microsoft Teams",
                                                            page: document.title.replace(" - Orgaimi", ""),
                                                        }
                                                    );
                                                }}
                                            >
                                                <div className="small-icon">
                                                    <div className="icon">
                                                        <FontAwesomeIcon icon={faMessage} />
                                                    </div>
                                                    <h5>Message</h5>
                                                </div>

                                                <h3>Teams Chat</h3>
                                            </a>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="card -office">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faLandmark} />
                                </div>
                                <h5>Primary Office</h5>

                                <h3>{subClient.homeOffice}</h3>
                            </div>
                            <div className={classNames("card -office", { "-span2": !subClient.crms[0].isActive })}>
                                <div className="txt-left">
                                    <h5>{getTerm("serviceLine")}</h5>
                                    <h3>{(subClient?.serviceLines || []).map((p) => p.text).join(", ")}</h3>
                                    <h5>{getTerm("industry")}</h5>
                                    <h3>{(subClient?.industries || []).map((i) => i.text).join(", ")}</h3>
                                    {subClient?.mergers && subClient.mergers.length > 0 && (
                                        <>
                                            <h5>Merger</h5>
                                            <h3>{(subClient?.mergers).map((i) => i?.text).join(", ")}</h3>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> By {getTerm("serviceLine")}
                        </h4>
                        <div className="chart-legend">
                            <BarComparison
                                data={charts["t12TotalRevenueByServiceLine"]}
                                onClick={(data) => {
                                    setExistingProjectsFilter("serviceLine", [{ text: data?.text, value: data.value }]);
                                }}
                                preSup="$"
                            />
                        </div>
                    </div>
                    <div className="card -span2">
                        <h4 className="txt -center -white -white">
                            <span data-tooltip={getTerm("tooltip12MonthRevenue")}>{getTerm("t12Revenue")}</span> By {getTerm("projectType")}
                        </h4>
                        <div className="chart-legend">
                            <BarComparison
                                data={charts["t12TotalRevenueByProjectType"]}
                                onClick={(data) => {
                                    setExistingProjectsFilter("projectType", data?.text);
                                }}
                                preSup="$"
                            />
                        </div>
                    </div>
                    <div className="card -span2">
                        <h4 className="txt -center -white">
                            {getTerm("growthPotential")} By {getTerm("serviceLine")} & {getTerm("projectType")}
                        </h4>
                        <div className="chart-legend">
                            <ChartPie
                                charts={[
                                    {
                                        data: charts["growthByServiceLine"],
                                        outerRadius: 105,
                                        innerRadius: 50,
                                        onClick: (data) => {
                                            setPotentialProjectsFilter("serviceLine", [{ text: data.label, value: data.labelId }]);
                                        },
                                    },
                                    {
                                        data: aggregateChildren(charts["growthByServiceLine"]),
                                        outerRadius: 170,
                                        innerRadius: 115,
                                        onClick: (data) => {
                                            setPotentialProjectsFilter("name", data.label);
                                        },
                                    },
                                ]}
                            />
                            <ExternalLegend
                                data={charts["growthByServiceLine"]}
                                onClick={(data) => {
                                    setPotentialProjectsFilter("practice", data.label);
                                }}
                            />
                        </div>
                    </div>
                    <div className="card -span2">
                        <h4 className="txt -center -white">Theme Scores</h4>
                        <div className="chart-radar">
                            <RadarPolarChart
                                data={charts["clientThemeScore"]}
                                radarKeys={[
                                    { dataKey: "clientGroup", color: COLOR_FONT },
                                    { dataKey: "client", color: COLOR_POTENTIAL },
                                ]}
                            />
                            <ExternalLegend
                                cssClasses={["-center"]}
                                data={[
                                    { text: getTerm("parentClient"), color: COLOR_FONT },
                                    { text: getTerm("subClient"), color: COLOR_POTENTIAL },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubClientContent;
