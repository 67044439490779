import React from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";

import ToggleSwitch from "$components/toggle-switch";

import { getTerm } from "$stores/dictionary";

const ProjectListLayout = () => {
    const location = useLocation();
    const isPotential = location.pathname === "/projects/potential";
    const title = isPotential ? getTerm("potentialProjects") : getTerm("existingProjects"); // TODO: <em>-ify
    const tooltip = isPotential ? getTerm("tooltipTopPotentialProjects") : null;

    return (
        <div id="project-list" className="page">
            <div className="main -spacer">
                <main>
                    <div className="main-wrapper">
                        <div className="flex-header">
                            <h1 data-tooltip={tooltip}>
                                <em>{title}</em>
                            </h1>
                            <ToggleSwitch options={[{ value: 0 }, { value: 1 }]} selectedIndex={!isPotential ? 1 : 0}>
                                <NavLink className={({ isActive }) => (isActive ? "item -active" : "item")} to="/projects/potential">
                                    {getTerm("potential")}
                                </NavLink>
                                <NavLink className={({ isActive }) => (isActive ? "item -active" : "item")} to="projects/existing">
                                    Existing
                                </NavLink>
                            </ToggleSwitch>
                        </div>
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ProjectListLayout;
