import React from "react";
import api from "$services/api";
import { toast } from "react-toastify";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const FeedbackUndo = ({ id, projectKey, onSubmit, analyticsLabel }) => {
    const appInsights = useAppInsightsContext();

    const undoUserFlag = async () => {
        const res = await api.delete(`/api/projects/potential/${id}/user-flag/${projectKey}`);

        if (res) {
            toast("Concern Retracted");
            if (onSubmit) {
                onSubmit();
            }
        }

        const eventName = analyticsLabel ? `Feedback - ${analyticsLabel} - Undo` : "Feedback - Undo";
        appInsights.trackEvent(
            { name: eventName },
            {
                page: document.title.replace(" - Orgaimi", ""),
            }
        );
    };

    return (
        <div>
            This Project Was Flagged By You As A Concern.{" "}
            <button className="button -text" onClick={undoUserFlag}>
                Click to undo
            </button>
        </div>
    );
};

export default FeedbackUndo;
