import React, { createContext, useState } from "react";
import { isBoolean } from "../utils/form-helper";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { capitalizeFirstLetter } from "$utils/text-helpers";
import { getTerm } from "$stores/dictionary";

export const PanelContext = createContext();
export const PanelProvider = PanelContext.Provider;
export const PanelConsumer = PanelContext.Consumer;

var panelTimeout = null;

const DEFAULT_PANELS = {
    filters: false,
    officeDetails: false,
    notifications: false,
};

export const PanelState = (props) => {
    const appInsights = useAppInsightsContext();
    const [panels, setPanels] = useState({
        ...DEFAULT_PANELS,
    });

    const togglePanel = (key, value) => {
        clearTimeout(panelTimeout);
        const useValue = isBoolean(value) ? value : !panels[key];

        const closePanels = Object.keys(panels).reduce((obj, k) => {
            if (k !== key) {
                obj[key] = false;
            }
            return obj;
        }, {});

        if (useValue) {
            appInsights.trackEvent({ name: `Panel - ${capitalizeFirstLetter(getTerm(key))}` }, {});
        }

        return setPanels({
            ...closePanels,
            [key]: useValue,
        });
    };

    const closePanel = (key) => {
        clearTimeout(panelTimeout);
        panelTimeout = setTimeout(() => {
            togglePanel(key, false);
        }, 700);
    };

    const clearTimer = () => {
        clearTimeout(panelTimeout);
    };

    return <PanelProvider value={{ panels, togglePanel, closePanel, clearTimer }}>{props.children}</PanelProvider>;
};
