import React, { useEffect, useState } from "react";
import classnames from "classnames";

import InitialsCell from ".";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const CollapsingInitials = ({ data, maxVisible, keyPrefix }) => {
    const appInsights = useAppInsightsContext();
    const [showExtra, setShowExtra] = useState(false);
    const [hover, setHover] = useState(false);

    const [visibleData, setVisibleData] = useState([]);
    const [hiddenData, setHiddenData] = useState([]);

    const onHover = () => {
        if (!showExtra) {
            setHover(true);

            appInsights.trackEvent(
                { name: "Show More - Initials" },
                {
                    page: document.title.replace(" - Orgaimi", ""),
                    action: "hover",
                }
            );
        }
    };

    const onLeave = () => {
        setHover(false);
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const max = maxVisible <= data.length ? maxVisible : data.length;
            const visibleData = data.slice(0, max);
            const hiddenData = data.slice(max) || [];
            setVisibleData(visibleData);
            setHiddenData(hiddenData);
        }
    }, [data, maxVisible]);
    return (
        <div className="collapsing-pills -initials">
            <div className="initials-list">
                {visibleData.length > 0 &&
                    visibleData.map((v, i) => {
                        return <InitialsCell key={`initials-${keyPrefix}-${i}`} isActive={v.isActive} value={v} />;
                    })}
            </div>
            <div className="extra-container">
                <div className={classnames("extra", { "-show": showExtra })}>
                    <div className="initials-list">
                        {hiddenData.length > 0 &&
                            hiddenData.map((v, i) => {
                                return <InitialsCell key={`initials-${keyPrefix}-${i}`} isActive={v.isActive} value={v} />;
                            })}
                    </div>
                </div>

                {hiddenData.length > 0 && (
                    <button
                        className={classnames("show-more", { "-show": showExtra })}
                        onMouseEnter={onHover}
                        onMouseLeave={onLeave}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowExtra(!showExtra);
                            setHover(false);
                            appInsights.trackEvent(
                                { name: "Show More - Initials" },
                                {
                                    page: document.title.replace(" - Orgaimi", ""),
                                    action: !showExtra ? "close" : "open",
                                }
                            );
                        }}
                    >
                        {showExtra ? "^" : `+ ${hiddenData.length}`}
                    </button>
                )}
                <div className={classnames("tooltip", { "-hover": hover })}>
                    <div className="initials-list">
                        {hiddenData.length > 0 &&
                            hiddenData.map((v, i) => {
                                return <InitialsCell key={`initials-${keyPrefix}-${i}`} isActive={v.isActive} value={v} />;
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollapsingInitials;
