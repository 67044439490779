import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import ProfileIcon from "$components/profile-icon";

import { Input } from "$components/form-inputs/inputs";
import { useInput } from "$utils/hooks/use-input";
import { KEYCODE } from "$utils/keycodes";
import { CreateProjectCommentsStore, postComment } from "$stores/projectComments";
import { formatRecentDate } from "$utils/date-helpers";
import { handleAbort } from "$services/api";
import { NotificationStore } from "$stores/notifications";

import "./comments.scss";

const COMMENT_CHARACTER_LIMIT = 1000;

const Comment = ({ name, isCommenterActive, timestamp, comment }) => {
    return (
        <div className="comment">
            <ProfileIcon name={name} isActive={isCommenterActive} modifierClassNames={["-small"]} />
            <div className="content">
                <div className="name-date">
                    <b>{name}</b> <span className="txt -xsmall -light">{formatRecentDate(timestamp)}</span>
                </div>

                <p>{comment}</p>
            </div>
        </div>
    );
};

const AddComment = ({ addComment }) => {
    const inputComment = useInput("");

    const onEnter = (e) => {
        if (e.which === KEYCODE.RETURN || e.keyCode === KEYCODE.RETURN) {
            handleAdd();
        }
    };

    const handleAdd = async () => {
        if (!inputComment.value.trim()) {
            toast("Please Add A Comment");
        } else if (inputComment.value.length > COMMENT_CHARACTER_LIMIT) {
            toast("The Comment Is Too Long");
        } else {
            await addComment(inputComment.value);
            inputComment.reset();
        }
    };
    const characterCount = inputComment.value.length;
    const charactersLeft = COMMENT_CHARACTER_LIMIT - characterCount;
    return (
        <div className="add-comment">
            <div className="button-group -full">
                <Input placeholder="Add Comment" name="comment" {...inputComment.bind} onKeyDown={onEnter} autoComplete="off" />
                <button className={classNames("button -small", { "-primary": inputComment.value, "-secondary": !inputComment.value })} onClick={handleAdd}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
            {characterCount >= COMMENT_CHARACTER_LIMIT - 100 && (
                <div className={classNames("txt -small", { "-error": charactersLeft < 0 })}>{`${COMMENT_CHARACTER_LIMIT - inputComment.value.length} characters remaining`}</div>
            )}
        </div>
    );
};

const Comments = ({ updateCommentCount, projectKey, analyticsLabel }) => {
    const [store, setStore] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        const storeItems = CreateProjectCommentsStore(projectKey);
        setStore(storeItems);
        const controllerComments = storeItems.fetchComments();
        setHasLoaded(true);

        return () => {
            handleAbort([controllerComments]);
        };
    }, []);

    return (
        <>
            {hasLoaded && store && (
                <CommentsDisplay store={store.ProjectCommentsStore} fetchComments={store.fetchComments} updateCommentCount={updateCommentCount} projectKey={projectKey} analyticsLabel={analyticsLabel} />
            )}
        </>
    );
};

const CommentsDisplay = ({ store, fetchComments, updateCommentCount = () => {}, projectKey, analyticsLabel }) => {
    const appInsights = useAppInsightsContext();
    const active = NotificationStore.useState((s) => s.active);
    const { comments, isLoading, commentCount } = store.useState((s) => {
        return {
            comments: s.comments,
            commentCount: s.comments.length,
            isLoading: s.isLoading,
        };
    });

    useEffect(() => {
        if (active && active.length > 0) {
            const notifiedProjectKey = active[0].context?.Project?.ProjectKey;
            if (notifiedProjectKey === projectKey) {
                fetchComments();
            }
        }
    }, [active]);

    const commentList = useRef();

    useEffect(() => {
        if (isLoading) {
            updateCommentCount("Loading...");
        } else {
            updateCommentCount(`${commentCount} comment${commentCount === 1 ? "" : "s"}`);
        }
    }, [isLoading, comments]);

    const addComment = async (value) => {
        await postComment(projectKey, value);

        if (appInsights) {
            const eventName = analyticsLabel ? `Comments - ${analyticsLabel}` : "Comments";
            appInsights.trackEvent(
                { name: eventName },
                {
                    page: document.title.replace(" - Orgaimi", ""),
                    action: "Create",
                }
            );
        }

        setTimeout(() => {
            fetchComments();
        }, 750);
    };

    const hasComments = comments.length > 0;

    return (
        <div className="comments-wrapper">
            <div ref={commentList} className={classNames("comment-list", { "-no-comments": !hasComments })}>
                <h5>{hasComments ? "Comments" : "No Comments"}</h5>

                {comments.map((c, i) => {
                    return <Comment key={`comment_${i}`} comment={c.comment} name={c.name} timestamp={c.timestamp} isCommenterActive={c.isCommenterActive} />;
                })}
            </div>
            <AddComment addComment={addComment} />
        </div>
    );
};

export default Comments;
