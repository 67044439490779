import { Store } from "pullstate";
import _ from "lodash";
import stringify from "json-stable-stringify";
import { SHA256 } from "crypto-js";

import logger from "$services/logger";
import api from "$services/api";

import { clearValue, hardPresistValue, retrieveValueSync } from "../../services/local";
import { capitalizeFirstLetter } from "../../utils/text-helpers";
import { buildFilters, buildSort } from "../../utils/api-helpers";
import { updateGlobalError } from "../app-context";
import { stringMatch } from "$utils/check-match";
import { getTerm } from "$stores/dictionary";

const PaginatedStore = ({ label, key, defaultSort, persistPagingInfo, storageKey, apiListUrl }) => {
    const initialState = {
        [key]: [],
        [`total${capitalizeFirstLetter(key)}`]: 0,
        canNextPage: true,
        canPreviousPage: false,
        currentFilters: {},
        filterHash: "",
        filterRanges: {},
        globalFilterRanges: {},
        filters: {},
        hiddenColumns: retrieveValueSync(`hiddenColumns_${key}`) || [],
        isLoading: true,
        isSortAsc: true,
        pageIndex: 0,
        pageSize: 10,
        pageCount: 1,
        paginationHash: "",
        persistPagingInfo: persistPagingInfo === false ? persistPagingInfo : true,
        selectedData: [],
        sort: defaultSort,
        totalCount: 0,
    };
    const store = new Store(initialState);

    const apiList = async (globalFilters, { pageIndex, pageSize = 10, sort, filters, keyPrefix = "" } = {}, apiSettings = {}) => {
        let filterHash;
        let paginationHash;

        await store.update((s) => {
            filterHash = SHA256(stringify(filters)).toString();
            paginationHash = SHA256(stringify({ pageIndex, pageSize, sort })).toString();
            //const isFilterChanged = !(s.filterHash.length > 0 && filterHash === s.filterHash);
            s.isLoading = true;
            //s.pageIndex = isFilterChanged ? 0 : pageIndex || 0; // TODO: ???
            return s;
        });
        try {
            logger.log(`Fetching ${apiListUrl} list`);
            // TODO: Work out cache
            const sortParamString = buildSort(sort);
            const filterParamString = buildFilters(filters, keyPrefix);
            const globalFiltersString = buildFilters(globalFilters, "");
            let paramString = "";

            if (sortParamString) {
                paramString = `&sort=${sortParamString}`;
            }
            if (filterParamString) {
                paramString = paramString + `&${filterParamString}`;
            }
            if (globalFiltersString) {
                paramString = paramString + `&${globalFiltersString}`;
            }

            const response = await api.get(`${apiListUrl}?page=${pageIndex || 0}&limit=${pageSize}${paramString ? `&${paramString}` : ""}`, null, apiSettings);
            //const response = await api.getFromCache(`${apiListUrl}?page=${pageIndex || 0}&limit=${pageSize}${paramString ? `&${paramString}` : ""}`, null, cancel?.token);
            store.update((s) => {
                const list = response.data;
                const totalCount = response.totalCount;
                s[key] = list;
                s[`total${capitalizeFirstLetter(key)}`] = totalCount;
                s.isLoading = false;
                s.currentFilters = filters;
                s.filterHash = filterHash;
                s.filterRanges = response.filterRanges || {};
                (s.globalFilterRanges = response.globalFilterRanges || {}), (s.paginationHash = paginationHash);
                s.pageCount = Math.ceil(totalCount / pageSize) || 1;
                s.totalCount = totalCount;
                return s;
            });
        } catch (e) {
            if (api.requestWasCanceled({ ...e })) {
                return;
            }
            updateGlobalError(`Unable to fetch ${getTerm(label || key)}`);
            logger.error(`Unable to fetch ${getTerm(key)}`, { ...e });
        }
    };

    const fetchList = (filters, pagingInfo = {}) => {
        const abortController = new AbortController();
        apiList(filters, pagingInfo, { signal: abortController.signal });
        return abortController;
    };

    const setLoading = () => {
        store.update((s) => {
            s.isLoading = true;
            return s;
        });
    };

    const setFlagged = (idList) => {
        store.update((s) => {
            s[key] = s[key].map((d) => {
                if (idList.includes(d.id)) {
                    d.userFlagged = true;
                }
                return d;
            });
            return s;
        });
    };

    const reset = async () => {
        clearValue(storageKey);
        const hiddenColumns = (await retrieveValueSync(`hiddenColumns_${key}`)) || [];

        store.update((s) => {
            return { ...initialState, hiddenColumns: hiddenColumns };
        });
    };

    const handlePageUrlParam = ({ pageIndex, pageSize }) => {
        if (persistPagingInfo && history.replaceState) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set("pageIndex", pageIndex);
            searchParams.set("pageSize", pageSize);

            if (!pageIndex || pageIndex === 0) {
                searchParams.delete("pageIndex");
            }
            if (!pageSize || pageSize === 10) {
                searchParams.delete("pageSize");
            }

            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
            window.history.replaceState({ path: newurl }, "", newurl);
        }
    };

    const setPageIndex = (newPageIndex) => {
        clearValue(storageKey);
        store.update((s) => {
            s.pageIndex = newPageIndex;
            handlePageUrlParam({ pageIndex: s.pageIndex, pageSize: s.pageSize });
            return s;
        });
    };

    const setPerPage = (newPageSize) => {
        //clearValue(storageKey);
        store.update((s) => {
            // If perpage puts you after last page, set to new last page
            const newMaxPage = Math.ceil(s.totalCount / newPageSize);
            if (s.totalCount > 0 && newMaxPage < s.pageIndex + 1) {
                s.pageIndex = newMaxPage - 1;
            }
            s.pageSize = newPageSize;
            handlePageUrlParam({ pageIndex: s.pageIndex, pageSize: s.pageSize });
            return s;
        });
    };

    const setHiddenColumns = (column, value) => {
        store.update((s) => {
            var newHiddenColumns = [...s.hiddenColumns];
            if (value) {
                newHiddenColumns.push(column);
            } else {
                newHiddenColumns = newHiddenColumns.filter((c) => c !== column);
            }
            s.hiddenColumns = _.uniq(newHiddenColumns);

            hardPresistValue(`hiddenColumns_${key}`, _.uniq(newHiddenColumns));

            return s;
        });
    };

    const setSelectedData = (action, data) => {
        store.update((s) => {
            switch (action) {
                case "add":
                    s.selectedData = _.unionBy([...s.selectedData, ...data], (p) => p.key);
                    return s;
                case "remove":
                    data.forEach((d) => {
                        s.selectedData = s.selectedData.filter((p) => p.key !== d.key);
                    });
                    return s;
                case "bulk":
                    if (data.every((d) => s.selectedData.some((p) => p.key === d.key))) {
                        s.selectedData = [];
                    } else {
                        s.selectedData = _.unionBy([...s.selectedData, ...data], (p) => p.key);
                    }
                    return s;
                case "clear":
                    s.selectedData = [];
                    return s;
                default:
                    return s;
            }
        });
    };

    const setFilters = (filters) => {
        let searchParams = new URLSearchParams(window.location.search);
        if (persistPagingInfo) {
            searchParams.set("filter", JSON.stringify(filters));
            searchParams.delete("pageIndex");

            if (!filters || _.isEmpty(filters)) {
                searchParams.delete("filter");
            }
        }

        const searchParamString = searchParams.toString();
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `${searchParamString ? "?" + searchParamString : ""}`;
        window.history.replaceState({ path: newurl }, "", newurl);

        store.update((s) => {
            s.filters = filters;
            s.pageIndex = 0;
            return s;
        });
    };
    //field, isSortAsc
    const setSort = (values) => {
        store.update((s, org) => {
            let update = false;
            if (!stringMatch(org.sort, values)) {
                s.sort = _.isEmpty(values) ? defaultSort : values;
                s.pageIndex = 0;
                update = true;
            }

            if (update) {
                clearValue(storageKey);

                if (persistPagingInfo && history.replaceState) {
                    let searchParams = new URLSearchParams(window.location.search);

                    if (persistPagingInfo) {
                        searchParams.set("sort", JSON.stringify(values));
                        searchParams.delete("pageIndex");

                        if (!values || _.isEmpty(values) || stringMatch(values, defaultSort)) {
                            searchParams.delete("sort");
                        }
                    }

                    const searchParamString = searchParams.toString();
                    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `${searchParamString ? "?" + searchParamString : ""}`;
                    window.history.replaceState({ path: newurl }, "", newurl);
                }
                return s;
            }
        });
    };

    const setPagingInfo = ({ sort, filter, pageIndex, pageSize }) => {
        store.update((s, org) => {
            let isUpdate = false;
            let searchParams = new URLSearchParams(window.location.search);
            if (!stringMatch(org.sort, sort)) {
                s.sort = _.isEmpty(sort) ? defaultSort : sort;
                s.pageIndex = 0;
                isUpdate = true;
            }
            if (!stringMatch(org.filters, filter)) {
                s.filters = _.isEmpty(filter) ? {} : filter;
                s.pageIndex = 0;
                isUpdate = true;
            }
            if (!isNaN(pageIndex) && pageIndex !== org.pageIndex) {
                s.pageIndex = pageIndex;
                isUpdate = true;
            } else {
                searchParams.delete("pageIndex");
            }
            if (!isNaN(pageSize) && pageSize !== org.pageSize) {
                s.pageSize = pageSize;
                isUpdate = true;
            } else {
                searchParams.delete("pageSize");
            }
            if (isUpdate) {
                clearValue(storageKey);
                if (persistPagingInfo && history.replaceState) {
                    searchParams.set("sort", JSON.stringify(sort));
                    searchParams.set("filter", JSON.stringify(filter));

                    if (!sort || _.isEmpty(sort) || stringMatch(sort, defaultSort)) {
                        searchParams.delete("sort");
                    }
                    if (!filter || _.isEmpty(filter)) {
                        searchParams.delete("filter");
                    }
                    /*else {
                        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
                        window.history.replaceState({ path: newurl }, "", newurl);
                    }*/
                    const searchParamString = searchParams.toString();
                    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `${searchParamString ? "?" + searchParamString : ""}`;
                    window.history.replaceState({ path: newurl }, "", newurl);
                }

                return s;
            }
        });
    };

    return {
        initialState,
        store,
        fetchList,
        setLoading,
        reset,
        setFilters,
        setFlagged,
        setHiddenColumns,
        setPageIndex,
        setPerPage,
        setSelectedData,
        setSort,
        setPagingInfo,
    };
};

export default PaginatedStore;
