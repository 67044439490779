import React, { useState, useEffect } from "react";
import { CommentsStore, fetchComments, deleteComment } from "./store.js";
import debounce from "debounce";
import { format } from "date-fns";
import usePagination from "../../hooks/usePagination";
import Pagination from "$components/table/components/pagination";

import "./comments.scss";
import ConfirmButton from "../../components/ConfirmButton.js";

const debounceFetchComments = debounce(fetchComments, 500);

const CommentsAdmin = () => {
    const comments = CommentsStore.useState((s) => s.comments);
    const [projectId, setProjectId] = React.useState("");
    const { page, setPage, limit, setLimit } = usePagination();

    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        debounceFetchComments(projectId, page, limit);
    }, [projectId, page, limit]);

    useEffect(() => {
        if (comments && comments.length > 0) {
            setTotalPages(Math.ceil(comments[0].totalRecordCount / limit));
        }
    }, [comments]);

    return (
        <div className="comments-admin">
            <h1>Comments</h1>
            <div>
                Filter by Project ID: <input type="text" value={projectId} onChange={(e) => setProjectId(e.currentTarget.value)} />
            </div>
            <table>
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Project Id</th>
                    <th>User</th>
                    <th>Comment</th>
                    <th>Actions</th>
                </tr>
                {comments.map((comment) => (
                    <tr key={comment.id}>
                        <td>{comment.id}</td>
                        <td>{format(new Date(comment.commentDate), "MMMM d, yyyy h:mm a")}</td>
                        <td>
                            <a href={`/project/${comment.projectId}/potential`}>{comment.projectId}</a>
                        </td>
                        <td>
                            {comment.firstName} {comment.lastName}
                        </td>
                        <td>{comment.commentText}</td>
                        <td>
                            <ConfirmButton
                                onClick={() =>
                                    deleteComment(comment.id).then(() => {
                                        setTimeout(() => {
                                            debounceFetchComments(projectId, page, limit);
                                        }, 500);
                                    })
                                }
                                text="Delete"
                            />
                        </td>
                    </tr>
                ))}
            </table>
            <Pagination
                pageIndex={page}
                pageCount={totalPages}
                pageSize={limit}
                setPageIndex={setPage}
                setPerPage={setLimit}
                canPreviousPage={page > 1}
                canNextPage={page < totalPages - 1}
                pageOptions={Array.from({ length: totalPages }, (x, i) => i)}
            />
        </div>
    );
};

export default CommentsAdmin;
