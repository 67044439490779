import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import Breadcrumb from "$components/breadcrumb";
import ClientNav from "./components/client-nav";
import ClientSharedDashboard from "./components/shared-dashboard";
import ShadowTable from "$components/loaders/shadow-table";

import { fetchChartsStore, resetCharts } from "$stores/charts";
import { fetchScoreCardScores, fetchRecommendations, ScoreCardStore } from "$stores/scorecard";
import { AppContextStore } from "$stores/app-context";
import { ClientDetailsStore, fetchClient, fetchPotentialProjects, fetchExistingProjects, fetchSubClients, resetClient } from "$stores/client-details";
import { scrollToPos } from "$utils/scroll-to";
import { handleAbort } from "$services/api";
import { clientDetailCharts } from "./lib/chart-helper";
import { getTerm } from "$stores/dictionary";

import "./client.scss";

const ClientDetailLayout = () => {
    const { clientId } = useParams();
    const [currentClient, setCurrentClient] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const scoreCardSummary = ScoreCardStore.useState((s) => s.summary);
    const overview = AppContextStore.useState((s) => s.overview);
    const clientDetails = ClientDetailsStore.useState((s) => s.clientDetails);

    useEffect(() => {
        scrollToPos(0);

        return () => {
            resetClient();
            resetCharts();
        };
    }, []);

    useEffect(() => {
        if (clientDetails) {
            setCurrentClient(clientDetails);
            setIsLoading(false);
        }
    }, [clientDetails]);

    useEffect(() => {
        const controllerClient = fetchClient(clientId);
        const controllerPotentialProjects = fetchPotentialProjects({ client: clientId });
        const controllerExistingProjects = fetchExistingProjects({ client: clientId });
        const controllerScoreCardScores = fetchScoreCardScores({ client: clientId });
        //const controllerOverview = fetchOverview({ client: clientId });
        const controllerRecommendations = fetchRecommendations({ client: clientId });
        const controllerSubClients = fetchSubClients({ client: clientId });

        const controllerCharts = fetchChartsStore({ clientGroup: clientId }, clientDetailCharts);

        return () => {
            handleAbort([controllerClient, controllerPotentialProjects, controllerExistingProjects, controllerScoreCardScores, controllerRecommendations, controllerSubClients, controllerCharts]);
        };
    }, [clientId]);

    useEffect(() => {
        if (currentClient && scoreCardSummary) {
            setIsLoading(false);
        }
    }, [currentClient, scoreCardSummary, overview]);

    const breadcrumbLinks = [
        { text: getTerm("parentClients"), url: "/clients" },
        { text: clientDetails?.name, url: `/client/${clientId}` },
    ];

    return (
        <div className="page-container">
            <div id="client" className="page">
                <Breadcrumb links={breadcrumbLinks} />
                <div className="main">
                    {isLoading && <ShadowTable />}
                    {!isLoading && (
                        <main>
                            <div className="main-wrapper">
                                <div className="page-content -shadow">
                                    <div className="panel-content">
                                        <ClientNav clientId={clientId} />
                                        <ClientSharedDashboard client={currentClient} scoreCardSummary={scoreCardSummary?.summary} />
                                        <div className="outlet">
                                            <Outlet />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ClientDetailLayout;
