import React, { useEffect, useState } from "react";
import api from "$services/api";
import logger from "$services/logger";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import Select from "$components/form-inputs/select";

import { getTerm } from "$stores/dictionary";
import { Textarea } from "$components/form-inputs/inputs";
import { useInput } from "$utils/hooks/use-input";

import "./feedback.scss";
import { getTeamFeebackOptions } from "./lib/data";

const FEEDBACK_CHARACTER_LIMIT = 250;

const TeamFeedback = ({ projectId, projectKey, employeeId, employeeName, onSubmit }) => {
    const appInsights = useAppInsightsContext();
    const [feedbackOptions, setFeedbackOptions] = useState([]);
    const reasonSelect = useInput("");
    const additionalInput = useInput("");

    useEffect(() => {
        const init = async () => {
            const results = await getTeamFeebackOptions();
            setFeedbackOptions(results || []);
        };

        init();
    }, []);

    const sendFeedback = async () => {
        if (!reasonSelect.value) {
            toast("Please Select A Reason");
            return false;
        }
        if (additionalInput.value.length > FEEDBACK_CHARACTER_LIMIT) {
            toast("Additional Information Is too Long");
            return false;
        }
        const payload = {
            reason: reasonSelect.value.text,
            feedback: additionalInput.value,
        };

        try {
            const res = await api.put(`/api/projects/potential/${projectId}/user-flag/${projectKey}/team/${employeeId}`, payload);

            if (res) {
                additionalInput.reset();
                reasonSelect.reset();

                toast("Feedback Sent Successfully!");

                if (onSubmit) {
                    onSubmit();
                }
            } else {
                toast("Error: Feed Back Not Recorded, Please Try Again");
            }
        } catch (e) {
            toast("Error: Feed Back Not Recorded, Please Try Again");
            logger.log(e);
            throw e; // throw error to be handled by feedback form
        }

        appInsights.trackEvent(
            { name: "Feedback - Team" },
            {
                label: "Team",
                action: "Sumbitted",
            }
        );
    };

    const characterCount = additionalInput.value.length;
    const charactersLeft = FEEDBACK_CHARACTER_LIMIT - characterCount;

    return (
        <div className="feedback">
            <h5>
                <em>Feedback</em> {employeeName}
            </h5>
            <p>Please indicate why you are recommending another experienced {getTerm("teamMember").toLowerCase()}.</p>
            <div>
                <Select label="Reason*" options={feedbackOptions} {...reasonSelect.bind} defaultValue={reasonSelect.value} />
            </div>

            <Textarea placeholder="Additional Information" {...additionalInput.bind}></Textarea>
            <div className="button-group">
                <div>
                    <span className="txt -small -detail-dark">* Required</span>
                    {characterCount >= FEEDBACK_CHARACTER_LIMIT - 100 && (
                        <div className={classNames("txt -small", { "-error": charactersLeft < 0 })}>{`${FEEDBACK_CHARACTER_LIMIT - additionalInput.value.length} characters remaining`}</div>
                    )}
                </div>
                <button className="button -primary -small -right" onClick={sendFeedback}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default TeamFeedback;
